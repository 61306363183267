@import "variables";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: $dark;
  min-height: 100vh;
  color: $light;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

input {
  margin-top: 12px;
  margin-bottom: 12px;
  height: 25px;
  padding: 3px 10px;
  width: 100%;
  background: $input-base;
  border: 1px solid $input-border;
  color: $light;

  &.has-error {
    border: 2px solid $danger;
  }
}
