.main-control-panel {
  display: flex;

  .lateral-menu {
    height: 100vh;
    min-width: 15%;
  }

  .working-area {
    width: 100%;
    height: 100vh;
    margin-left: 20px;
  }

}
