.overlay {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0);
  display: none;

  &.open {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .modal {
    width: 50%;
    background: #46484a;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 25px 25px 25px;
    -webkit-box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.75);
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.75);

    .modal-header {
      width: 100%;
      margin-bottom: 25px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      h2 {

      }

      .closeButton {
        cursor: pointer;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        font-size: 3em;
      }
    }

    .modal-content {
      width: 100%;
      flex-grow: 1;
    }

    .modal-footer {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-top: 15px;
    }
  }

}