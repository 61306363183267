@import "src/style-guide/variables";

.domain-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  p.welcome {
    font-family: $font-primary;
    font-size: 3em;
    margin-top: 50px;
  }

  ul {
    width: 75%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
    margin-top: 50px;

    li {
      border: 1px solid $input-base;
      border-radius: 10px;
      list-style: none;
      margin: 15px;
      display: inline;
      width: 200px;
      padding: 10px 0;

      &:hover {
        cursor: pointer;
        background: darken($input-base, 10%);
      }

      div {
        width: 100%;
        display: flex;
        flex-direction: column;

        div {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;

          img {
            width: 6em;
            height: 6em;
          }
        }

        p {
          text-align: center;
        }
      }

    }
  }
}
