@import "src/style-guide/variables";

.icon-picker {
  display: flex;
  align-items: center;
  min-height: 250px;

  .icon-preview {
    margin-right: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 150px;

    img {
      width: 100px;
      height: 100px;
      border: 1px solid $input-border;
      padding: 5px;
      margin-bottom: 5px;

      &:hover {
        cursor: pointer;
      }

      &.has-error {
        border: 2px solid $danger;
      }
    }
  }

  .picker-panel {
    display: none;
    max-height: 250px;
    width: 80%;
    overflow-y: scroll;
    transition: width 0.5s, height 0.5s, transform 0.5s;

    &.closed {
      transform: perspective(-100%);
    }

    &.open {
      display: block;
      transform: perspective(0);
    }

    .icons-section {
      width: 100%;

      .icons-present {
        display: flex;
        flex-wrap: wrap;

        img {
          width: 48px;
          margin-right: 12px;
          margin-bottom: 12px;
          border: 1px solid #abb1b6;
          padding: 5px;

          &:hover {
            background: #4c708c;
            cursor: pointer;
          }
        }
      }
    }
  }
}




