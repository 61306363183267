@import "src/style-guide/components";

Form {
  display: flex;
  flex-direction: column;

  div.fields {
    display: flex;
    min-height: 250px;

    .right {
      display: flex;
      flex-direction: column;
      padding-top: 24px;

      &.hidden {
        display: none;
      }

      div {
        display: flex;
        flex-direction: column;
        margin-bottom: 12px;
      }
    }
  }

  .form-footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 15px;
  }

  button {
    @include base-button;
    min-width: 120px;
    min-height: 40px;
    margin-left: 20px;
  }
}

