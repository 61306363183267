@import "src/style-guide/variables";
@import "src/style-guide/components";

.login-screen {

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  h1 {
    font-family: $font-primary, $font-primary-style;
    font-size: 40px;
    margin-top: 50px;
  }

  button {
    @include base-button;
    height: 40px;
    margin-left: 20px;
    border: thin solid black;
    padding: 5px;
    width: 30%;
    margin-top: 20px;
    min-height: 60px;

    span.icon {
      display: inline-block;
      vertical-align: middle;

      img {
        width: 42px;
      }
    }

    span.buttonText {
      display: inline-block;
      vertical-align: middle;
      padding-left: 42px;
      padding-right: 42px;
      font-size: 14px;
      font-weight: bold;
      font-family: 'Roboto', sans-serif;
    }

    &:hover {
      cursor: pointer;
    }

    &.login-google {
      background: white;
      color: #444;
    }
  }
}
