$light: #abb1b6;
$dark: #2b2b2b;
$primary: #365880;
$secondary: #5e6060;
$disabled: #3c3f41;

$input-base: #4c5052;
$input-border: lighten($input-base, 10%);

$success: #499c54;
$info: #3592c4;
$warning: #ffc107;
$danger: #8b0000;

$blue: #007bff;
$dark-blue: #051e34;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #28a745;
$teal: #20c997;
$cyan: #17a2b8;
$white: #fff;
$gray: #6c757d;
$gray-dark: #343a40;

$font-primary: 'Nunito';
$font-primary-style: cursive;